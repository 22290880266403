import React from "react";
import { Link } from "gatsby";
import inline from './InlineStyles.js';

export default ({children}) => {
  return (
    <nav style={inline.nav}>
      <Link to="/">
      <h1> Dagistips.se </h1>
      </Link>

      <Link style={inline.navA} to="/regnklader"> Regnkläder </Link>
      <Link style={inline.navA} to="/namnlappar"> Namnlappar & Märkning </Link>
      <Link style={inline.navA} to="/pedagogiska-leksaker"> Pedagogiska leksaker & Experiment </Link>
      <Link style={inline.navA} to="/somn"> Sömn och rutiner kring sovstund </Link>
      <Link style={inline.navA} to="/skor"> Skor, stövlar och inneskor </Link>
      <Link style={inline.navA} to="/leksaker"> Leksaker för olika säsonger </Link>
      {children}
    </nav>
  )
}
