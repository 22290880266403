import React, {useLayoutEffect, useState} from 'react';
import Nav from './nav'
import Cookieconcent from './cookieConcent';
import styles from './index.module.css';
import Footer from './footer';
import Helmet from 'react-helmet';

export default function({ children }){
  const [linkCount, setLinkCount] = useState(0)

  const mapLinks = {
    'babyland.se': "https://pin.babyland.se/t/t?a=1066444612&as=1556616723&t=2&tk=1",
    'babyshop.se': "https://pin.babyshop.se/t/t?a=1146297052&as=1556616723&t=2&tk=1",
    'bonti.se': "https://go.bonti.se/t/t?a=1553081095&as=1556616723&t=2&tk=1",
    'namnlappar.se': 'https://id.namnlappar.se/t/t?a=1261185030&as=1556616723&t=2&tk=1',
    'forskarfabriken.se': 'https://track.adtraction.com/t/t?a=1433999279&as=1556616723&t=2&tk=1',
    'polarnopyret.se': 'https://pin.polarnopyret.se/t/t?a=1126522829&as=1556616723&t=2&tk=1',
  }

  const links = new Set()

  function convertUrl(url,map){
    return mapLinks[map] + "&url=" + url;
  }

  function linkLifter(){
    links.clear();
    document.querySelectorAll('a').forEach((item) => {
      const urls = ['namnlappar.se','polarnopyret.se','forskarfabriken.se','bonti.se','babyland.se','babyshop.se']

      let updatedUrl = null;
      urls.some((url, i) => {
        if (item.href.indexOf(url) !== -1) {
            updatedUrl = convertUrl(item.href, urls[i]);
            links.add(updatedUrl);
            return true
          }
      });
    });

    setLinkCount(links.size);
    document.body.addEventListener('touchstart',  showQuicklink);
  }

  function openLinks() {
    let i = 0
    links.forEach((url) => {
      if(url){

        window.open(url, i);
      }
        i++
    });
  }


  function showQuicklink() {
    let quickLinks = document.getElementById('quickLinks');

    quickLinks.style.display = "block";
    quickLinks.addEventListener('click', openLinks);

    if(!document.cookie.split('; ').find(row => row.startsWith('showQuickLinkToolTip'))) {
      let tooltip = document.getElementById('quickLinksTooltip');
      tooltip.style.display = "flex";
      setTimeout(hideTooltip, 3000);
    }
  }

  function hideTooltip(){
    console.log("hideTooltip");
    let tooltip = document.getElementById('quickLinksTooltip');
    var i = 0;

    let moveElement = setInterval(reduceToZero, 15);

    function reduceToZero(){
      if(i < 100){
        i = i + 1;
        tooltip.style.bottom = "-"+ i +"%";
      } else {
        document.cookie = "showQuickLinkToolTip=true; max-age=60";
        clearInterval(moveElement);
        tooltip.style.display ="none";
      }
    }

  }

  useLayoutEffect(() => {
      linkLifter();
  })

  return (
  <div>
  <Helmet htmlAttributes={{
    lang: 'sv',
  }}>
  <meta name="robots" content="index" />
  {/* Global site tag (gtag.js) - Google Analytics  */}
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-6FCXXGL1JL"></script>
  <script>
  {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date());

    gtag('config', 'G-6FCXXGL1JL');
    `}
  </script>
  </Helmet>
    <header>
      <Nav>
      </Nav>
      <Cookieconcent></Cookieconcent>
    </header>
    <div id="testdiv" className={styles.pageContainer}>
    {children}
    <div className={styles.quickLinksContainer}>
      <div id="quickLinksTooltip" className={styles.quickLinksTooltip}>
        <p>Klicka här om du vill öppna produktlänkar i nya flikar </p>
      </div>
      <div id="quickLinks" className={styles.quickLinks}>
        <p>Browsa<br />{linkCount}<br />{linkCount > 1 ? 'produkter' :'produkt'}</p>
      </div>
    </div>

    </div>
    <Footer>
    </Footer>
  </div>
  )
}
