export default class Inline  {
  static nav = {
    display:'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  }

  static navA = {
    paddingLeft:  30 +'px',
    paddingRight: 30 +'px',
    marginBottom: 10 +'px',
    color: '#2E4053',
    fontSize: 1.2 +'em'
  }

  static h1 = {
    color: '#2E4053',
    fontFamily: 'Open Sans',
    textAlign: 'left',
    minWidth: 200 + 'px',
    fontWeight: 300
  }

  static p = {
    marginBottom: 35 + 'px'
  }
}

// body {
//   margin: 0px;
//   font-family: 'Open Sans', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
//
// code {
//   font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// nav a {
//   padding-left: 30px;
//   padding-right: 30px;
//   margin-bottom: 10px;
//   color: #2E4053;
//   font-size: 1.2em;
// }
//
// h2 {
//   color: #2E4053
// }
//
// a {
//   text-decoration: none;
//   color: #296B9B;
//   font-family: 'Open Sans';
//   font-weight: 600
// }
//
// a:hover {
//   font-weight: bold;
//   color: #348ec9;
// }
//
// footer {
//   width: 100%;
//   justify-content: flex-start;
//   background-color: #F4F6F6;
//   display: inline-flex;
//   flex-wrap: wrap-reverse;
// }
//
// footer p:first-of-type {
//   font-size: 0.75em;
// }
//

//
// img {
//   max-width: 960px;
//   border-radius: 10px;
// }
//
// .footerContainer {
//   display: inline-block;
//   margin-left: 50px;
// }
//
// .footerContainer p {
//   max-width: 400px;
// }
//
// .pageContainer {
//   max-width: 900px;
//   display: inline-flex;
//   align-content: space-around;
//   flex-wrap: wrap;
//   align-items: center;
// }
//
// .pageContainer h1 {
//   padding: 0 3% 0 3%;
//   font-size: 1.4em;
// }
//
// .pageContainer p {
//   padding: 0 3% 0 3%;
// }
//
// .productCategory {
//   display: flex;
//   flex-flow: wrap;
//   justify-content: flex-start;
// }
//
//
// .productCategoryShortDescription {
//   padding: 3%;
//   text-align: left;
// }
//
// .productCategoryMainContainer {
//   display: inline-flex;
//   align-content: space-around;
//   max-width: 900px;
// }
//
// .productCategoryMainDescription {
//   display: inline-flex;
//   flex-wrap: wrap;
// }
//
//
// .productCategoryMainDescriptionHeader {
//   display: inline-block;
//   justify-content: center;
//   margin-bottom: 20px;
//   margin-top: 3.5em;
//   margin-left: 2em;
//   margin-right: 2em;
// }
//
// .productCategoryMainDescriptionHeader h1 {
//   font-size: 3em;
// }
//
// .productCategoryMainContainer li {
//   list-style: none;
//   padding: 5px;
// }
//
// .productCategoryMainContainer ul {
//   padding-inline-start: 20px;
//   margin-bottom: 5em;
// }
//
// .productCategoryTipList {
//   list-style-type: none;
// }
//
// .productCategoryMainDescription li::before{
//    content: '';
//    display: inline-block;
//    height: 20px;
//    width: 20px;
//    background-image: url('../images/checkmark.png');
//    background-image: url('../images/checkmark.png');
//    background-repeat: no-repeat;
//    background-size: 20px 20px;
//    margin-right: 10px;
// }
//
//
// .productCategoryMainDescriptionIntro {
//   display: inline-flex;
//   flex-wrap: wrap;
//   align-items: flex-start;
// }
//
// .productCategoryMainDescriptionIntro p {
//   max-width: 800px;
//   margin-right: 10px;
//   margin-left: 10px;
// }
//
// .productCategoryMainText {
//   width: inherit;
//   text-align: left;
//   display: inline-flex;
//   margin-right: 10px;
//   margin-left: 10px;
// }
//
// .cookieConcentContainer {
//   margin-left: 10px;
//   margin-right: 10px;
//   position: absolute;
//   border-radius: 10px;
//   border-style: solid;
//   border-width: thin;
//   padding: 20px;
//   display: inline;
//   background-color: #d1d9e3;
//   max-width: 800px;
//   align-items: center;
// }
//
// .imageDescription {
//
//   font-size: 0.75em;
// }
//
// .sponsor {
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   margin: 0;
//   width: 100%;
//   height: 2em;
//   text-align: center;
//   background-color: #2E4053;
//   color: #99a3ad;
//   weight: 200;
// }
//
// .card {
//   margin: 0em 0em 3em 0em;
//   padding: 0em 0.5em 0em 0.5em;
//   background-color: #fafcff;
// }
//
// .cardFlexContent {
//   display: flex;
//   align-items: flex-start;
// }
//
// .card p {
//   color: #000000;
//   font-size: 0.8em;
//   font-weight: lighter;
//   text-align: justify;
//   margin-right: 1em;
// }
//
// .card h2 {
//   font-size: 1.1em;
// }
//
// .card img {
//   margin-top: 0.5em;
//   border-radius: 0px;
// }
//
//
// table, th, td {
//   border: 0px;
//   border-collapse: collapse;
// }
//
// th {
//   background: #d1d9e3;
// }
//
// tr:nth-child(odd) {
//   background: #F4F6F6;
//
// }
//
// .emphasisRow {
//   font-weight: bolder;
// }
//
// @media (max-width: 480px){
//   nav a {
//     width: 100%;
//   }
//
//   img {
//     max-width: 250px;
//   }
//
//   .sponsor {
//     height: 4em;
//   }
// }
