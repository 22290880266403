import React, { useEffect, useState } from 'react';
import styles from './index.module.css'

const CookieConcent = () => {

  const [allowCookies, setAllowCookies] = useState(true);
  const [cookiesConfirmed, setCookiesConfirmed] = useState(true);

  useEffect(() => {
    localStorage.getItem('cookiesConfirmed') == null ? setCookiesConfirmed(false) : setCookiesConfirmed(true);
    setCookiesConfirmed(localStorage.getItem('cookiesConfirmed'));
  }, [cookiesConfirmed]);

  const allowCookiesLocalStorage = () => {
    setCookiesConfirmed(true);
    setAllowCookies(true);
    localStorage.setItem('allowCookies', allowCookies);
    localStorage.setItem('cookiesConfirmed', true);
  }

  return (
    !cookiesConfirmed && <div class={styles.cookieConcentContainer} >
    <h3>Denna sida bjuder på kakor</h3>
    <p>För att använda denna webbplats behöver du tillåta att cookies lagras på din dator i syfte att följa upp användarstatistik och marknadsföringsaktiviteter.</p>
    <button onClick={allowCookiesLocalStorage}>Ok</button>
    <a href="http://google.com">
    <button> Neka </button>
    </a>
    </div>
  )
}

export default CookieConcent
