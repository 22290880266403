import React from 'react';
import styles from './index.module.css'

export default function Footer(){
return (
<footer>
  <div className={ styles.footerContainer }>
      Under uppbyggnad
  <p>
    Dagistips.se är under uppbyggnad och kommer att kompletteras med information löpande.
  </p>
</div>
<div className={ styles.footerContainer }>
    Kontakta oss
  <p>
    Kontakta oss gärna om du har tips om förbättringar eller funderingar kring informationen som publicerats.
  </p>
  <a href="mailto:info@dagistip.se">
    <p>
      info@dagistips.se
    </p>
  </a>
</div>
</footer>
)
}
